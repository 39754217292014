import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TermSale = () => (
  <React.Fragment>
    <Header />
    <section className="terms-sale-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="heading">
            <h2>Terms of sale</h2>
          </div>
        </div>

        <div className="row justify-content-center mb-4">
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Important Consumer information</h5>
              <p>
                Birk goods come with guarantees that cannot be excluded under
                the Australian Consumer Law. You are entitled to a replacement
                or refund for a major failure and for compensation for any other
                reasonable foreseeable loss or damage. You are also entitled to
                have the goods repaired or replaced if the goods fail to be of
                acceptable quality and the failure does not amount to a major
                failure.
              </p>
              <p>
                These terms apply to all goods and services ("Products") that a
                customer ("You") orders from Birk.
              </p>

              <h5>Quote</h5>
              <p>
                Birk's quote (a) is not an offer to supply Products (but you can
                place an order against it by signing the quote as an order and
                paying the deposit), (b) expires after 30 days if you do not
                place an order, and (c) is an estimate until final site
                measurement, when correct pricing will be determined
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Contract</h5>
              <p>
                If Birk accepts your order, there is a contract as per the quote
                and these terms. These terms override anything else in the
                contract.
              </p>

              <h5>Extra Charges</h5>
              <p>
                Where an extra charge is optional, Birk will obtain your
                approval before it is incurred. Where it is not optional (a) it
                will be reasonable but (b) Birk will notify you of the amount
                and you may cancel your contract within 2 business days
              </p>

              <h5>Child Safety Regulations</h5>
              <p>
                Birk will install child safety clips with window furnishings, as
                required by law
              </p>

              <h5>Variations</h5>
              <p>
                Changes to a product order, delivery date or arrangements at
                your request are only effective if (a) we agree in writing and
                (b) you accept any delay and extra charges that arise from your
                request
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-4">
          <div className="col-12 col-md-12">
            <div className="terms-sale-content sales-box mb-3">
              <h5>Payment</h5>
              <p>
                To proceed with an order, please sign your Birk quote and return
                / email a copy of ALL PAGES of the signed quote to your Birk
                consultant.
              </p>
              <h5>PAYMENT: (FOR ONLINE ORDERS VIA BIRK PORTAL ONLY)</h5>
              <p>
                100% payment is required to confirm your order (non-refundable)
                and orders will be placed via online payment gateway.
              </p>

              <h5>Payment stages:</h5>
              <p className="ml-4">
                50% deposit is required to confirm your order (non-refundable).
              </p>
              <p className="ml-4">
                50% payment when booking your installation date.
              </p>

              <h5>How to make payment:</h5>
              <h6>Visa or Mastercard (no surcharges):</h6>
              <p className="ml-4">By Phone: 03 9464 54443</p>

              <h6>Direct deposit</h6>
              <ul className="ml-4 list-unstyled">
                <li>Account Name: Birk Solutions Pty Ltd</li>
                <li>Bank: National Australia Bank</li>
                <li>BSB: 083 125</li>
                <li>Account Number: 19714 4664</li>
              </ul>

              <p>
                Please enter your Job Number as Payment Reference, then email
                the remittance to admin@birk.com.au
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-4">
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Delays</h5>
              <p>
                If installation is delayed Birk may invoice an extra charge for
                storage of goods (except if Birk caused the delay). Extra site
                visits to double check measurements or conditions may affect the
                price and delivery time.
              </p>

              <h5>Scheduled Supply Times</h5>
              <p>
                These are good faith estimates only, and may be affected by
                factors beyond Birk's control. Subject to the consumer
                guarantees, delay is not a breach of the contract
              </p>

              <h5>Supply and Installation</h5>
              <p>
                Birk will supply products within a reasonable time (a) at the
                installation site in the quote or (b) if there is no
                installation site - from Birk's premises. Unless Birk agrees
                otherwise in writing, Birk supplies products (including
                installation) between 8am and 5pm on weekdays, except public
                holidays.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Tenancies, Owners Corporations, Councils, etc</h5>
              <p>
                If you order products for premises that are rented, subject to
                an owners' corporation or otherwise owned or controlled by a
                third party, or where council permission is required for
                installation (a) you must advise Birk accordingly (b) you must
                provide written evidence of the consent or permission and (c)
                Birk may suspend the supply of products until you have done so,
                or require payment as per “If you are not ready for scheduled
                installation”.
              </p>

              <h5>Ownership and Risk</h5>
              <p>
                Goods are (a) Birk's property until you have paid in full for
                them and (b) at your own risk when delivered to you or the
                installation site.
              </p>

              <h5>If you are not ready for scheduled installation</h5>
              <p>
                If products are ready for scheduled installation but you are not
                ready (eg. you do not have owners' corporation consent or your
                site is not prepared) Birk may require that you pay for the
                products (but not installation charges), take delivery and store
                them until you are ready for installation (when installation
                charges will be payable).
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-4">
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Installation Site Conditions</h5>
              <p>
                You must, at your cost (unless included in the quote) make the
                installation site clear, accessible, safe for Birk's team and
                structurally suitable for installation of products. Birk may
                suspend supply of products until you have done so, or have
                agreed to pay an extra charge to arrange it.
              </p>

              <h5>Existing window furnishings</h5>
              <p>
                You must, at your own cost (unless included in the quote),
                remove and dispose of any existing window furnishings. Birk may
                suspend supply of products until you have done so, or have
                agreed to pay an extra charge to arrange it
              </p>

              <h5>Electrical Work</h5>
              <p>
                You must, at your cost, arrange any initial or follow-up
                electrical works required for motorised blinds. Birk may suspend
                supply of products until you have done so, or have agreed to pay
                an extra charge to arrange it.
              </p>
              <h5>Online Sales Terms</h5>
              <p>
                Birk has carefully crafted the packages with the automation
                options based on the plans supplied by builder. Post the check
                measure, if for some reason the product planned is not suitable
                for the window, then Birk will contact the customer and give
                options for either another product / solution or refund for that
                product only (out of entire contract). There may be additional
                charges involved based on the product chosen for replacement.
              </p>

              <h5>Consumer Guarantees</h5>
              <p>
                Rights and remedies for PDH goods and services If Birk supplies
                you with products ordinarily acquired for Personal, Domestic or
                Household use or consumption (abbreviated as "PDH"), you have
                extensive rights under the Australian Consumer Law ("ACL")
                including consumer guarantees ("Consumer Guarantees") and
                remedies.
              </p>
              <p className="font-weight-bold">
                NOTHING IN THESE TERMS LIMITS THOSE RIGHTS AND REMEDIES IN ANY
                WAY.
              </p>
              <p>
                Rights and remedies for non-PDH goods costing no more than
                $40,000 If Birk supplies you with non-PDH products costing no
                more than $40,000, you have consumer guarantees under the ACL
                but: (a) in relation to goods, Birk's liability for failure to
                comply with a consumer guarantee (other than certain guarantees
                about ownership and undisturbed use) is limited to: (i)
                replacing the goods or supplying equivalent ones; (ii) repairing
                the goods; (iii) paying the cost of replacing the goods or of
                acquiring equivalent ones; or (iv) paying the cost of having the
                goods repaired and (b) in relation to services, Birk's liability
                for failure to comply with a consumer guarantee is limited to:
                (i) supplying the services again; or (ii) paying the cost of
                having the services supplied again
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Payment default</h5>
              <p>
                If you fail to pay an amount when due: (a) Birk may charge
                interest at 2.5% per month (b) Birk may repossess
                products(before or after installation) (c) you indemnify Birk
                against any loss or expense (including legal expenses) Birk
                incurs in recovering payment or repossessing products and (d)
                Birk may suspend or terminate your contract.
              </p>
              <h5>Warranties and Guarantees</h5>
              <p>
                You have the benefit of the consumer guarantees described by
                clause Consumer Guarentees. Subject to that, all other
                guarantees, warrantees, terms, conditions, rights and remedies
                in respect of products are excluded.
                <br />
                Birk provides warranty on workmanship, programming, and
                commissioning only. Product warranties are provided by the
                manufacturer or supplier of the product installed.
              </p>
              <h5>Service Call Charges</h5>
              <p>
                Subject to the consumer guarantees, these apply after the first
                60 days of installation. All warranty repairs must be brought to
                Birk warehouse, else callout fees and delivery fees will be
                payable if repairs are requested onsite after 60 days from
                product installation.
                <br />A callout fee of $139 (incl. GST) plus any travel costs is
                payable at the time of booking the service call. This covers the
                first 20 mins on site, the initial inspection and any work that
                can be completed in that time. This does not include products /
                parts that need replacing or the re-delivery & installation
                costs. If further time, products or components are required a
                quote will be supplied for your acceptance.
              </p>
              <h5>Other Liability</h5>
              <p>
                Subject to other clauses, Birk is not liable to you for, and you
                release Birk from and indemnify Birk against, any harm, loss,
                damage, claim, demand, action (including without limitation, any
                indirect or consequential loss or damage) that you or anyone
                else may suffer or be entitled to make or bring in connection
                with or arising out of the supply or failure to supply products,
                including but not limited to by virtue of breach of contract or
                negligence by Birk or anyone for whose acts and omissions Birk
                is vicariously responsible
              </p>
              <h5>Force Majeure</h5>
              <p>
                Subject to the consumer guarantees, Birk is not liable for any
                breach of contract to the extent that the breach is due to
                circumstances beyond Birk's reasonable control.
              </p>
              <h5>Governing Law</h5>
              <p>
                Your contract is governed by the laws and courts of the state
                where Birk supplies products to you, the exception being if Birk
                does not have a permanent showroom and/or staff in that state,
                the law and courts of Victoria apply.
              </p>
              <h5>GST</h5>
              <p>Prices and charges include GST.</p>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="heading">
            <h2>BIRK BLINDS PRODUCT AWARES</h2>
          </div>
        </div>

        <div className="row justify-content-center mb-5">
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h6 className="font-weight-bold text-orange-logo">
                IMPORTANT INFORMATION (“AWARES”) FOR CONSUMERS TO CONSIDER WHEN
                BUYING INDOOR AND OUTDOOR WINDOW FURNISHINGS
              </h6>

              <h5>General information relating to all Birk Products</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Regular servicing and cleaning of your products is
                    essential. Failure to comply with this will shorten the life
                    of your product. Contact Birk for more information.
                  </li>
                  <li>
                    Window furnishing products are not designed to be closed
                    over open windows.
                  </li>
                  <li>
                    Customer to ensure that adequate fixing points are
                    available. This is not the responsibility of Birk Solutions
                  </li>
                  <li>
                    All electrical wiring is to be arranged by the customer.
                    Birk will supply applicable leads and a location drawing to
                    be supplied to your electrician.
                  </li>
                  <li>
                    Window locks, winder and other features may interfere with
                    installed products.
                  </li>
                  <li>
                    Corrosion can occur in high salt areas. Componentry is
                    subject to corrosion in coastal areas.
                  </li>
                  <li>Fabrics retain memory and will rectify themselves.</li>
                  <li>
                    Birk cannot guarantee that fabric supplied will match
                    exactly to the sample from which it was ordered, due to
                    dyelot variations. Small imperfections may occur in the
                    fabric.
                  </li>
                  <li>
                    Matching trims/componentry to fabrics is at the discretion
                    of production.
                  </li>
                  <li>
                    From time-to-time our suppliers experience stock shortages
                    and delay’s that are out of our control. We endeavor to
                    supply products as quickly as possible and will inform you
                    of your options should this occur.
                  </li>
                </ol>
              </div>

              <h5>Curtains and Soft Furnishings</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Fabric deemed to be flawed prior to manufacture will be
                    replaced which may cause installation delays.
                  </li>
                  <li>
                    Velvet is made pile side up unless otherwise requested.
                  </li>
                  <li>
                    Manmade fibers used in soft furnishing fabrics i.e. rayon,
                    acetate and viscose can have a tendency to billow.
                  </li>
                  <li>
                    Inherently, Linen/linenrich fabrics will “drop”, however
                    windows will be measured, and drapes manufactured to the
                    measured drop.
                  </li>
                  <li>
                    When seamed, SelfCoated fabrics may result in pinholes,
                    allowing light through seams and hems.
                  </li>
                  <li>Curtain tracks longer than 6000mm will have a join.</li>
                  <li>
                    All curtain hems will be selected by Birk unless the client
                    advises a preference before the order is placed.
                  </li>
                  <li>
                    The bottom of all curtains will finish within 15mm above the
                    floor level.
                  </li>
                  <li>
                    Curtains can appear to have inconsistent spacing a floor
                    level when the floor level is uneven.
                  </li>
                  <li>
                    Fabrics are not solid substance and can move due to
                    atmospheric conditions. The fabric movement in either
                    shrinkage or dropping will change the shape of the fabric. A
                    possible shrinkage is possible upon cleaning.
                  </li>
                </ol>
              </div>

              <h5>Roman Blinds</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Birk recommend Roman Blinds be facefitted and to extend
                    blinds over architraves between 2050mm. Recess fitting of
                    Roman Blinds is not recommended.
                  </li>
                  <li>
                    Stack heights will vary between 20mm and 40mm depending on
                    the drop of the blind.
                  </li>
                  <li>
                    Corner windows may have up to a 40mm gap between blinds when
                    down to compensate for “stacking”
                  </li>
                  <li>
                    Romans with different size drops will have different size
                    panels. Birk can match panels at additional cost.
                  </li>
                  <li>
                    Due to the nature of fabric Roman Blinds may drop up to
                    40mm.
                  </li>
                  <li>
                    On continuous and butting blinds, blinds of varying size may
                    drop at different rates and battens may not align over a
                    period of time.
                  </li>
                  <li>
                    Corner window blinds may not be able to be stacked up at the
                    same time.
                  </li>
                  <li>
                    Roman Blind fabrics tend to pucker, ripple and may not sit
                    straight.
                  </li>
                  <li>
                    Depending on the selected fabric width, Soft Roman Blinds
                    may be finished with “Wing Joins” (visible panel seams on
                    the face).
                  </li>
                </ol>
              </div>

              <h5>Plantation Shutters</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Plantation Shutters modified to suit the window will have
                    the edge finished with a touch up kit
                  </li>
                  <li>
                    Plantation Shutter midrail heights will vary depending on
                    the number of blades. Midrails may vary up to 50mm from the
                    requested height.
                  </li>
                  <li>
                    Plantation Shutters may need to be lifted on the bottom when
                    closing and will sit on pads when closed in most cases.
                  </li>
                  <li>
                    Grain or colour variations occur due to the natural
                    characteristics that may occur on any painted, stained,
                    lacquered or oiled timber product.
                  </li>
                  <li>
                    Custom colour matching will be as close as possible to the
                    customer’s sample. Variation may occur due to type paint and
                    timber used.
                  </li>
                  <li>
                    Plantation Shutters are treated with UV inhibitors to limit
                    the consequences of the Australian sun. This will not stop
                    discoloration but slows the process.
                  </li>
                  <li>
                    It is a requirement that shutter blades be in the closed
                    position if opening/closing bi-fold and by-pass shutters to
                    avoid damage.
                  </li>
                  <li>
                    Be aware, not all Shutter options can be used in wet areas
                    (near sinks and in bathrooms).
                  </li>
                </ol>
              </div>

              <h5>Zip Guide and Wire Guide Blinds</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Expansion and contraction will occur depending on the
                    elements
                  </li>
                  <li>Condensation can temporarily cause white frosty marks</li>
                  <li>Fabrics retain memory and will rectify themselves.</li>
                  <li>Large blinds may have some waving in the fabric.</li>
                  <li>
                    Ziptrak and Wire guide blinds must be raised if the winds
                    are more than a breeze. Blinds left down in strong winds or
                    wind gusts can be pulled out of their fittings and this
                    resulting damage is not covered by warranty.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="terms-sale-content mb-3">
              <h5>Roller Blinds (Hollands)</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Blinds fitted in the recess will have side and top light
                    gaps
                  </li>
                  <li>
                    Birk recommends facefitting Block-out Roller Blinds to
                    minimise side light gaps.
                  </li>
                  <li>
                    Recess blinds may interfere with the removal of flyscreens.
                  </li>
                  <li>
                    Continuous and corner blinds will have a light gap at the
                    join/corner between the blinds.
                  </li>
                  <li>
                    Setting of products e.g. bowing (“ving”) or twisting and
                    wrinkles may take approximately 3 months to settle.
                  </li>
                  <li>
                    Railroaded or oversized blinds are not recommended, as they
                    may not hang flat, may pucker along the bottom rail or a ‘V
                    formation’ may occur. A horizontal join may also be visible
                    at the top section of the blind.
                  </li>
                  <li>Fabric joins may occur, depending on the blind size.</li>
                  <li>
                    Hemless blinds may fray on the edge if the blind is not
                    rolling straight. It is the customers’ responsibility to
                    ensure the blind continues to roll straight.
                  </li>
                  <li>
                    Care must be taken when operating the chain to not rub it
                    against the side of the fabric when raising/ lowering
                    otherwise fraying will occur. This damage is not covered by
                    warranty
                  </li>
                  <li>
                    Narrow blinds under 500mm in width are not recommended and
                    will have to be manually assisted to roll up straight.
                    Blinds that have a width:height ratio exceeding 1:2.5 will
                    not be covered by warranty and will need to be manually
                    assisted to roll up straight.
                  </li>
                  <li>
                    Blinds are made square therefore we cannot be responsible
                    for uneven gaps caused by out of square windows.
                  </li>
                  <li>
                    Bottom rails may not always sit square when rolled up.
                  </li>
                  <li>
                    Screen and Sheer Roller Blinds must be finished with a Base
                    Rail to have enough weight to function correctly. Some
                    screens and sheers can have a sewn pocket. Check with your
                    sales consultant if this is a preference.
                  </li>
                  <li>
                    Large blinds may require assistance to open/close (Spring
                    Assist/Motorised).
                  </li>
                  <li>
                    Translucent or sunscreen fabrics are not recommended for
                    total privacy.
                  </li>
                  <li>
                    Common Fascia’s and Pelmets wider than 3000mm may have a
                    join.
                  </li>
                  <li>
                    Acceptability of defects
                    <br />
                    ◦ If the defect is visible with naked eye in natural
                    daylight at a distance of 1.2meters, it is not acceptable.
                    <br />◦ If the defect is not visible with naked eye in
                    natural daylight at a distance of 1.2meters, it is
                    acceptable.
                  </li>
                </ol>
              </div>

              <h5>Vertical and Panel Blinds</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Vertical and Panel Blinds are measured to allow up to 10
                    15mm between the bottom of the blind and the sill or floor.
                  </li>
                  <li>
                    Recessed Vertical Blinds are measured to allow 10mm
                    clearance on either side. Facefitted blinds will have a gap
                    of up to 90mm unless a blockout blade is installed.
                  </li>
                  <li>
                    Overlaps on Vertical and Panel Blinds may allow light to
                    penetrate.
                  </li>
                  <li>
                    Panel Blinds will have one panel width on the window (when
                    stacking back) unless there is enough room to stack panels
                    beyond the architraves.
                  </li>
                  <li>
                    Vertical Blind slats with chainless or sewn in weights will
                    move around and take longer to settle.
                  </li>
                </ol>
              </div>

              <h5>Manmade and Natural Timber Venetian Blinds</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Large Timber Venetians do not close tightly, and total
                    privacy is not achievable.
                  </li>
                  <li>Venetian Blinds are not a full blockout product.</li>
                  <li>
                    Natural Timber Venetians are treated with UV Inhibitors to
                    limit the consequences of the Australian sun. This will not
                    stop discoloration but slows the process.
                  </li>
                  <li>
                    Variations may occur to grain and stain with natural
                    timbers.
                  </li>
                  <li>
                    Large venetians do not close tightly, and total privacy may
                    be compromised.
                  </li>
                  <li>Venetian Blinds are not a full blockout product</li>
                  <li>
                    Sunset venetians reduce the incidence of light via the holes
                    punched in the slats. This type of blind only closes tightly
                    in one direction and light will still filter through the
                    overlapping blades.
                  </li>
                </ol>
              </div>

              <h5>Folding Arm Awnings</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Joins are noticeable on larger and translucent awnings.
                  </li>
                  <li>
                    Due to the nature of the fabric, Screen and Acrylic fabrics
                    may not sit evenly and may contract and expand dependent on
                    the environment.
                  </li>
                  <li>
                    Puckering may occur at the sides of seams or joins. This is
                    a natural characteristic of an awning.
                  </li>
                  <li>150mm arms may cause the awning not to sit straight.</li>
                  <li>Manually operated awnings are not recommended</li>
                  <li>Fabrics may appear to illuminate in strong sunlight.</li>
                  <li>
                    Do not leave awnings or sunblinds extended in the wind or
                    rain as this could cause damage which is not covered by
                    warranty.
                  </li>
                  <li>Dependent on the fabric, do not roll up when wet.</li>
                </ol>
              </div>

              <h5>Product care instructions</h5>
              <div className="list-sales">
                <ol className="ml-4">
                  <li>
                    Open and close product regularly so dust does not accumulate
                  </li>
                  <li>
                    Use the appropriate attachment on a vacuum to gently remove
                    dust if required.
                  </li>
                  <li>
                    For shutters and venetians regular cleaning with an
                    anti-static cloth will assist in removing dust.
                  </li>
                  <li>
                    Spot clean products with mild detergent. Test in a small
                    spot in an inconspicuous area on the product first.
                  </li>
                  <li>
                    When spot cleaning do not allow the area to become fully
                    wet, use a damp cloth only.
                  </li>
                  <li>
                    For curtains follow instructions on care label for cleaning.
                  </li>
                  <li>
                    Use curtain flick sticks to open and close curtains to
                    minimise touching fabric.
                  </li>
                  <li>
                    Specialist blind and curtain cleaners can be found online
                    and if in doubt you may call them.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default TermSale;
